import { render, staticRenderFns } from "./Invest-multiples-projects.vue?vue&type=template&id=b78fa198&"
import script from "./Invest-multiples-projects.vue?vue&type=script&lang=js&"
export * from "./Invest-multiples-projects.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports